import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import BackgroundImage from 'gatsby-background-image'

const StyledWrapper = styled.div`
  height: 360px;
  margin-bottom: 50px;
  width: 100%;
  position: relative;

  &:hover {
    &:after {
      opacity: 1;
    }

    a {
      opacity: 1;
    }

    p {
      transform: translateY(0);

      &:after {
        opacity: 0;
      }
    }

    h3 {
      transform: translateY(0);
    }
    
    .secondaryImage {
      opacity: 1;
    }
  }

  ${({theme}) => theme.mq.s} {
    min-height: 300px;
    height: calc(80vh - ${({theme}) => theme.navbarHeight.s}px);
  }
`;

const StyledImagesContainer = styled.div`
  position: relative;
  height: 360px;
  overflow: hidden;
  
  ${({theme}) => theme.mq.s} {
    min-height: 300px;
    height: calc(80vh - ${({theme}) => theme.navbarHeight.s}px);
  }
`;


const StyledPrimaryImageWrapper = styled.div`
  height: 100%;
  position: relative;
`
const StyledSecondaryImageWrapper = styled.div`
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: .75s ease;

  &:hover {
    opacity: 1;
  }
`

const StyledTextTile = styled.div`
  background-color: ${({theme}) => theme.color.black};
  position: absolute;
  z-index: 10;
  color: ${({theme}) => theme.color.white};
  text-transform: uppercase;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
  display: grid;
  justify-content: center;
  text-align: center;
  grid-gap: 13px;
  padding: 2rem .5rem;
  box-sizing: border-box;

  p {
    font-weight: 200;
    font-size: 1.5rem;
    letter-spacing: 1px;
    transform: translateY(15px);
    cursor: default;
    transition: .35s ease;

    ${({theme}) => theme.mq.m} {
      font-size: 1.3rem;
    }

    ${({theme}) => theme.mq.s} {
      font-size: 1.2rem;
    }

    &:after {
      display: block;
      content: '';
      height: 1px;
      width: 4rem;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 18px);
      background-color: ${({theme}) => theme.color.white};
      transition: .35s ease;
    }
  }

  h3 {
    transition: .35s ease;
    font-family: ${({theme}) => theme.font.serif};
    letter-spacing: 2px;
    transform: translateY(40px);
    cursor: default;
    font-size: 2.6rem;

    ${({theme}) => theme.mq.m} {
      font-size: 2.3rem;
    }

    ${({theme}) => theme.mq.s} {
      font-size: 1.8rem;
    }
  }

  a {
    transition: .4s ease;
    opacity: 0;
  }
`;

const NewsTile = ({
                    name, title, images, slug,
                  }) => (
  <StyledWrapper>
    <StyledImagesContainer>
      <StyledPrimaryImageWrapper>
        <BackgroundImage fluid={images.primary} style={{backgroundPosition: "50% 15%", height: '100%'}}/>
      </StyledPrimaryImageWrapper>
      <StyledSecondaryImageWrapper className="secondaryImage">
        <BackgroundImage fluid={images.secondary} style={{backgroundPosition: "50% 15%", height: '100%'}}/>
      </StyledSecondaryImageWrapper>
    </StyledImagesContainer>
    <StyledTextTile>
      <p>{title}</p>
      <h3>{name}</h3>
      <Button size="small" color="light" to={`/models/${slug}`} component="link">MORE</Button>
    </StyledTextTile>
  </StyledWrapper>
);

export default NewsTile;
