import React, {useMemo} from 'react';
import Layout from 'components/layout/Layout';
import {graphql} from 'gatsby';
import Container from '../components/containers/Container';
import NewsTile from '../components/NewsTile';
import Grid from '../components/containers/Grid';
import SEO from "../components/Seo";

const News = ({data, location}) => {
  const models = useMemo(() => data.allDatoCmsNew.edges
    .sort((a, b) => ( a.node.position - b.node.position ))
    .map(({node}) => {
      const {
        name, title, link, imagePrimary, imageSecondary, id,
      } = node;
      return {
        id,
        name,
        title,
        images: {
          primary: imagePrimary.imageFile.childImageSharp.fluid,
          secondary: imageSecondary.imageFile.childImageSharp.fluid,
        },
        slug: link.slug,
      };
    }), []);
  return (
    <Layout location={location}>
      <SEO title="NEWS"/>
      <Container size="m">
        <Grid
          cols="repeat(3, 1fr)"
          colsM="repeat(2, 1fr)"
          colsXS="repeat(1, 1fr)"
          gap="35px"
          padding="40px 10px 0 10px"
        >
          {models.map(model => <NewsTile key={model.id} {...model} />)}
        </Grid>
      </Container>
    </Layout>
  );
};

export default News;

export const query = graphql`
    query AllNews {
        allDatoCmsNew {
            edges {
                node {
                    id
                    name
                    title
                    position
                    imagePrimary {
                        imageFile {
                            childImageSharp {
                                fluid {
                                    src
                                    base64
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    presentationHeight
                                    aspectRatio
                                    sizes
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                    imageSecondary {
                        imageFile {
                            childImageSharp {
                                fluid {
                                    src
                                    base64
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    presentationHeight
                                    aspectRatio
                                    sizes
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                    link {
                        slug
                    }
                }
            }
        }
    }
`;
