import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {createMq} from "styles/theme";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({cols}) => cols};
  grid-gap: ${({gap}) => gap};
  justify-items: ${({justify}) => justify};
  align-items: ${({align}) => align};
  padding: ${({padding}) => padding};

  ${({theme, customL}) => customL ? createMq(customL) : theme.mq.l} {
    grid-template-columns: ${({colsL}) => colsL};
  }

  ${({theme, customM}) => customM ? createMq(customM) : theme.mq.m} {
    grid-template-columns: ${({colsM}) => colsM};
    padding: ${({paddingM}) => paddingM};
  }

  ${({theme, customS}) => customS ? createMq(customS) : theme.mq.s} {
    grid-template-columns: ${({colsS}) => colsS};
    padding: ${({paddingS}) => paddingS};
  }

  ${({theme, customXS}) => customXS ? createMq(customXS) : theme.mq.xs} {
    grid-template-columns: ${({colsXS}) => colsXS};
    padding: ${({paddingXS}) => paddingXS};
  }
`;

const Grid = ({
                children,
                cols,
                gap,
                gapM,
                gapS,
                justify,
                align,
                colsL,
                colsM,
                colsS,
                colsXS,
                padding,
                paddingM,
                paddingS,
                as,
                customL,
                customM,
                customS,
                customXS,
              }) => (
  <StyledGrid
    as={as}
    justify={justify}
    align={align}
    gap={gap}
    gapM={gapM || gap}
    gapS={gapS || gapM || gap}
    cols={cols}
    colsL={colsL || cols}
    colsM={colsM || cols}
    colsS={colsS || colsM || cols}
    colsXS={colsXS || colsS || colsM || cols}
    padding={padding}
    paddingM={paddingM || padding}
    paddingS={paddingS || paddingM || padding}
    customL={customL}
    customM={customM}
    customS={customS}
    customXS={customXS}
  >
    {children}
  </StyledGrid>
);

Grid.defaultProps = {
  cols: 'auto',
  gap: '1rem',
  justify: 'center',
  align: 'center',
  as: 'div',
};

Grid.propTypes = {
  cols: PropTypes.string,
  gap: PropTypes.string,
  justify: PropTypes.string,
  align: PropTypes.string,
  as: PropTypes.string,
};

export default Grid;
